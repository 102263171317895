import React from 'react';
import {get} from 'lodash';
import Textarea from 'react-textarea-autosize';

import BaseAnswer from '../BaseAnswer';
import ReadOnly from './ReadOnly';
import ImmersiveReaderSwitch from 'common/challenge/ImmersiveReaderSwitch';
import {processStringContent} from 'utils/content';
import {ErrorMessageAction} from "mindsets-js-sdk";
import {store} from '../../../../../index';


export default class TextArea extends BaseAnswer {
    defaultAnswer() {
        return ''
    }

    onChange() {
        return e => {
            e.preventDefault()
            if (e.target.value.length <= 2000) {
                this.setState({answer: e.target.value})
            } else {
                store.dispatch(ErrorMessageAction('Please shorten your response. Your response should be at most 2000 characters.'));
            }
        }
    }

    getPlaceholder() {
        const placeholder = get(this.props.component_data, 'data.placeholder')
        if (placeholder) {
            return processStringContent(placeholder, {
                convert_html: false,
                convert_math: false
            })
        } else {
            return "Write an answer here..."
        }
    }

    renderImmersiveReader() {
        if (this.state.answer && !get(this.props.component_data, 'config.disable_reader')) {
            return (
                <ImmersiveReaderSwitch
                    challenge_version={this.props.challenge_version}
                    stream_entity={{}}
                    from={`Student Answer`}
                    content={this.state.answer}
                    tiny={true}
                />
            )
        }
    }

    renderReadOnly() {
        if (this.props.is_preview) {
            return (
                <ReadOnly
                    is_readonly={this.props.is_preview}
                    existing_answer={this.props.existing_answer}
                    placeholder={this.getPlaceholder()}
                />
            )
        }
    }

    renderTextarea() {
        if (!this.props.is_preview) {
            return (
                <div className="component-textarea-container">
                    <Textarea
                        value={this.state.answer}
                        placeholder={this.getPlaceholder()}
                        onChange={this.onChange()}
                        minRows={2}
                    >
                    </Textarea>

                    {this.renderImmersiveReader()}
                </div>
            )
        }
    }

    render() {
        return (
            <div className="challenge-component-textarea max-width-850">
                {this.renderReadOnly()}

                {this.renderTextarea()}
            </div>
        )
    }
}
